<template>
   <div class="col-12 px-0 mb-1" :class="toggleCodigos ? 'main-container' : ''" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-xl-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-sm-start cursor-pointer" @click="toggleCodigos = !toggleCodigos;">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6 col-sm-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-file color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Categoria:</strong>
                           <span> {{ produto.categoria == null || String(produto.categoria).trim().length == 0 ? 'Sem categoria' : produto.categoria }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-sm-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-copy color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Subcategoria:</strong>
                           <span> {{ produto.subcategoria == null || String(produto.subcategoria).trim().length == 0 ? 'Sem subcategoria' : produto.subcategoria }}</span>
                        </p>
                     </div>
                     <div class="col-sm-4 d-none d-sm-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-bookmark color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Marca:</strong>
                           <span> {{ produto.marca == null || String(produto.marca).trim().length == 0 ? 'Sem marca' : produto.marca }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="!showSelect">
                  <div class="btn-icone color-theme">
                     <span><i class="far fa-file-import"></i><small>Notas</small></span>
                  </div>
                  <div class="btn-icone color-theme" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar produtos')">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar produtos')">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="toggleCodigos">
         <div class="col-12 mb-3">
            <h1 class="font-15 mt-1 mb-0"><i class="fas fa-barcode font-13 color-theme me-2"></i>Códigos</h1>
         </div>

         <codigo v-for="(codigo, index) in produto.codigos" :key="index" :codigo="codigo" :index="index" :showSelect="showSelect" :tempImpressao="tempImpressao" 
            @remover="removerCodigo($event)" @select="selectCodigo($event)" @imprimir="imprimirCodigo($event)" />
         <div class="col-12 mt-4 mb-5 text-center" v-if="produto.codigos.length == 0">
            <i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
            <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum código encontrado</h4>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import codigo from '@/components/produtos/meusProdutos/Codigo.vue'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'showSelect', 'tempImpressao'],
   data: function () {
      return {
         toggleCodigos: false
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   components: {
      codigo
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.produto)
      },
      imprimirCodigo : function (codigo) {
         this.$emit('imprimirCodigo', codigo)
      },
      selectCodigo : function (codigo) {
         this.$emit('select', codigo)
      },
      removerCodigo : function (index) {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir código?',
            text: 'O código será permanentemente excluído.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
   
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
   
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/deleteCodigo',
                  params: {
                     id: this.produto.codigos[index].id
                  }
               }).then(() => {
                  this.produto.codigos.splice(index, 1)
   
                  this.$toast.fire({
                     icon: 'success',
                     title: 'Código excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')
   
                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir produto?',
            text: 'O produto e seus códigos serão excluídos.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/deleteProduto',
                  params: {
                     id: this.produto.id
                  }
               }).then(() => {
                  this.$refs.produto.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Produto excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>