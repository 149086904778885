<template>
   <div class="col-12 px-0">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-md-block">
                  <img src="@/assets/images/iconeCodigo.png" alt="icon" @error="imageError" height="45" />
               </div>
               <div class="col-sm text-center text-sm-start">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ codigo.ean == null ? 'SEM GTIN' : codigo.ean }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ codigo.id == null ? '?' : codigo.id }} </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-info-circle color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Obs.:</strong>
                     <span> {{ codigo.observacao == null || String(codigo.observacao).trim().length == 0 ? 'Sem observação' : codigo.observacao }}</span>
                  </p>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme" v-if="showSelect">
                     <span @click="select"><i class="far fa-check d-block"></i><small>Selecionar</small></span>
                  </div>
                  <div class="btn-icone" :class="tempImpressao.map(c => c.id).includes(codigo.id) ? 'impressao' : 'color-theme'" v-if="tempImpressao && !showSelect && codigo.ean && dadosUsuario.usuarioPermissoes.includes('Produtos | Imprimir etiquetas')">
                     <span @click="imprimir" class="ps-3"><i class="far fa-print d-block"></i><small>{{ tempImpressao.includes(codigo.id) ? 'Remover' : 'Imprimir' }}</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="!showSelect && dadosUsuario.usuarioPermissoes.includes('Produtos | Editar produtos')">
                     <span @click="remover"><i class="far fa-trash d-block"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Codigo',
   props: ['codigo', 'index', 'showSelect', 'tempImpressao'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      imprimir : function () {
         this.$emit('imprimir', this.codigo)
      },
      select : function () {
         this.$emit('select', this.codigo)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>

<style scoped>

.color-theme .fa-print::after,
.impressao .fa-print::after {
   font-weight: 400;
   position: absolute;
   top: 0px;
   right: 15px;
   font-size: 80%;
}

.impressao:hover .fa-print::after {
   top: -3px;
   font-size: 100%;
}

.color-theme .fa-print::after {
   content: "\f067"; /* PLUS */
}

.btn-icone.impressao span i,
.btn-icone.impressao span small {
   color: #33cc66 !important;
}

.btn-icone.impressao:hover span i,
.btn-icone.impressao:hover span small {
   color: #c23e4a !important;
}

.impressao .fa-print::after {
   content: "\f00c"; /* CHECK */
}

.impressao:hover .fa-print::after {
   content: "\f00d"; /* TIMES */
}

</style>