<template>
   <div class="card mb-2">
      <div class="card-body pb-0">
         <div class="row">
            <div class="col-6 col-md-4 col-xl-2 mb-3">
               <label><i class="far fa-file color-theme font-11 me-1"></i> Categoria</label>
               <v-select name="Desktop" :options="listaCategorias" v-model="pesquisa.categoria" label="nome" :reduce="c => c.nome" placeholder="Todas as categorias"
                  @input="buscarSubcategorias(pesquisa.categoria)">
                  <template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
                  <template v-slot:no-options>
                     <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
                  </template>
               </v-select>
               <select name="Mobile" class="form-control" v-model="pesquisa.categoria" @change="buscarSubcategorias(pesquisa.categoria)">
                  <option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
               </select>
            </div>
            <div class="col-6 col-md-4 col-xl-2 mb-3">
               <label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria</label>
               <v-select name="Desktop" :options="listaSubcategorias" v-model="pesquisa.subcategoria" placeholder="Todas as subcategorias">
                  <template v-slot:no-options>
                     <span class="d-block lh-sm my-1">Sem resultados</span>
                     <small class="d-block lh-sm mb-1 opacity-90 font-11" v-if="pesquisa.categoria">Utilize a tela "Categorias" para adicionar</small>
                     <small class="d-block lh-sm mb-1 opacity-90 font-11" v-else>Selecione uma categoria</small>
                  </template>
               </v-select>
               <select name="Mobile" class="form-control" v-model="pesquisa.subcategoria">
                  <option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
               </select>
            </div>
            <div class="col-md-4 col-xl-2 mb-3">
               <label><i class="far fa-bookmark color-theme font-11 me-1"></i> Marca</label>
               <v-select name="Desktop" :options="listaMarcas" v-model="pesquisa.marca" label="nome" :reduce="m => m.nome" placeholder="Todas as marcas">
                  <template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
                  <template v-slot:no-options>
                     <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Marcas" para adicionar</small>
                  </template>
               </v-select>
               <select name="Mobile" class="form-control" v-model="pesquisa.marca">
                  <option v-for="(option, index) in listaMarcas" :key="index" :value="option.nome">{{ option.nome }}</option>
               </select>
            </div>
            <div class="col-md-8 col-xl-4 mb-3">
               <label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome/Código/Família</label>
               <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarProdutos" placeholder="Busque por nome do produto, código ou família" />
            </div>
            <div class="col-12 col-md-4 col-xl-2 px-0 mb-3 align-self-end">
               <div class="row">
                  <div class="col">
                     <button type="button" class="btn btn-primary w-100" @click="buscarProdutos"><i class="fas fa-search font-13"></i></button>
                  </div>
                  <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar produtos') && showAdd">
                     <button type="button" class="btn btn-primary w-100" @click="adicionar"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Pesquisa',
   props: ['showAdd'],
   data: function () {
		return {
			pesquisa: {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null},
			listaSubcategorias: []
		}
	},
	computed: {
      ... mapState({
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      adicionar : function () {
         this.$emit('adicionar')
      },
      buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.pesquisa.subcategoria = null
				return
			}

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data
            
				if (data.length > 0) {
					this.pesquisa.subcategoria = data[0]
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		buscarProdutos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/searchProdutos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.$emit('setRetorno', response.data)

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
   }
}

</script>